
        import * as module0 from '../images/portfolio/dark_fdj.png';import * as module1 from '../images/portfolio/dark_fdj@2x.png';import * as module2 from '../images/portfolio/dark_hexastep.png';import * as module3 from '../images/portfolio/dark_hexastep@2x.png';import * as module4 from '../images/portfolio/dark_idean.png';import * as module5 from '../images/portfolio/dark_idean@2x.png';import * as module6 from '../images/portfolio/dark_mindfulchef.png';import * as module7 from '../images/portfolio/dark_mindfulchef@2x.png';import * as module8 from '../images/portfolio/dark_quiqup.png';import * as module9 from '../images/portfolio/dark_quiqup@2x.png';import * as module10 from '../images/portfolio/fdj.png';import * as module11 from '../images/portfolio/fdj@2x.png';import * as module12 from '../images/portfolio/hexastep.png';import * as module13 from '../images/portfolio/hexastep@2x.png';import * as module14 from '../images/portfolio/idean.png';import * as module15 from '../images/portfolio/idean@2x.png';import * as module16 from '../images/portfolio/mindfulchef.png';import * as module17 from '../images/portfolio/mindfulchef@2x.png';import * as module18 from '../images/portfolio/quiqup.png';import * as module19 from '../images/portfolio/quiqup@2x.png'
        const modules = {
            "../images/portfolio/dark_fdj.png": module0,
            "../images/portfolio/dark_fdj@2x.png": module1,
            "../images/portfolio/dark_hexastep.png": module2,
            "../images/portfolio/dark_hexastep@2x.png": module3,
            "../images/portfolio/dark_idean.png": module4,
            "../images/portfolio/dark_idean@2x.png": module5,
            "../images/portfolio/dark_mindfulchef.png": module6,
            "../images/portfolio/dark_mindfulchef@2x.png": module7,
            "../images/portfolio/dark_quiqup.png": module8,
            "../images/portfolio/dark_quiqup@2x.png": module9,
            "../images/portfolio/fdj.png": module10,
            "../images/portfolio/fdj@2x.png": module11,
            "../images/portfolio/hexastep.png": module12,
            "../images/portfolio/hexastep@2x.png": module13,
            "../images/portfolio/idean.png": module14,
            "../images/portfolio/idean@2x.png": module15,
            "../images/portfolio/mindfulchef.png": module16,
            "../images/portfolio/mindfulchef@2x.png": module17,
            "../images/portfolio/quiqup.png": module18,
            "../images/portfolio/quiqup@2x.png": module19,
        };
        export default modules;
      